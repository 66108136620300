import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/pieces-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1982 slasher movie, Pieces"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 03</h1>
                    <h2>Pieces</h2>
                    <h3>March 20, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Juan Piquer Simón // <b>Starring:</b> Christopher George &amp; Linda Day George</p>
                        <div className="player">
                            <iframe src="https://podcasters.spotify.com/pod/show/bryan-jude-white/embed/episodes/03-Pieces-e20p9gd" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe Horror Podcast episode 3, Pieces"></iframe>
                        </div>
                        <p><Link to="/transcripts/pieces">Read the episode transcript</Link></p>
                        <p>This week, Bryan and Dave take a look at the utterly deranged 1982 slasher picture, Pieces. This one has it all: Chainsaws, gratuitous nudity, buckets of blood, hysterical screaming, and THRILLING TENNIS ACTION! "It's exactly what you think it is."</p>
                        <p>Bastards!!!</p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/maniac">Next Episode</Link></li>
                        <li><Link to="/episodes/butcher-baker-nightmare-maker">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)